import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Callout = makeShortcode("Callout");
const Link = makeShortcode("Link");
const Tout = makeShortcode("Tout");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Community Projects`}</h1>
    <p>{`Propose your own work or help other community members with their projects.`}</p>
    <p>{`Visit our `}<a parentName="p" {...{
        "href": "https://github.com/makerdao/community/projects/2?card_filter_query=label%3A%22help+wanted%22"
      }}>{`help wanted board,`}</a>{` comment on open issues, or contact the owner.`}</p>
    <p>{`Read more about Community Development and self-directed projects.`}</p>
    <h2>{`Community Development Initiative Proposals (CDIPs)`}</h2>
    <p>{`Get access to up to 5000 Dai of funding by proposing your own idea. We use Community Development Initiative Proposals (CDIPs) to determine the financing of self-directed projects.`}</p>
    <p>{`A CDIP is the scoping and framing tool for anyone interested in receiving funds for proposing new MakerDAO community initiatives or resources.`}</p>
    <Callout icon="question" secondary mdxType="Callout">
      <p>{`Looking to contribute to an existing project? Visit our `}<Link to="/work-with-us/bounties/index.mdx" mdxType="Link">{`bounties page.`}</Link></p>
    </Callout>
    <h3>{`Examples`}</h3>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <p>{`Ryan Cordell revamped the content in our Community Portal to help us migrate from GitBooks.`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/makerdao/community/issues/543"
            }}>{`Content Audit Proposal`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/makerdao/community/issues/225"
            }}>{`GitBook Migration Request`}</a></li>
        </ul>
      </Box>
      <Box mdxType="Box">
        <p>{`@asterix modified the transparency dashboard to highlight data on Community Development initiatives like hackathons and meetups.`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://grants.makerdao.com/"
            }}>{`View Dashboard`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/makerdao/community/issues/463"
            }}>{`Transparency Dashboard v1.5 Refactor Proposal`}</a></li>
        </ul>
      </Box>
      <Box mdxType="Box">
        <p><a parentName="p" {...{
            "href": "https://forum.makerdao.com/u/scottrepreneur"
          }}>{`@scottrepreneur`}</a>{` created a bot for Maker Chat that responds to commands and provides community members with resources and protocol data.`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://chat.makerdao.com/channel/chakachat"
            }}>{`View Bot`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/makerdao/community/issues/412"
            }}>{`Rocket.Chat Bot Proposal`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://chat.makerdao.com/"
            }}>{`Maker Chat`}</a></li>
        </ul>
      </Box>
      <Box mdxType="Box">
        <p><a parentName="p" {...{
            "href": "https://forum.makerdao.com/u/reuptaken/summary"
          }}>{`@reuptaken`}</a>{` translated our walk-through video guides for MakerDAO products into Spanish.`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=qsT7fAJi2Y8&feature=youtu.be"
            }}>{`View Video`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/makerdao/community/issues/499"
            }}>{`Oasis Walk-Through (Spanish)`}</a></li>
        </ul>
      </Box>
    </Tout>
    <h2>{`Questions`}</h2>
    <p>{`Reach out to us on the `}<a parentName="p" {...{
        "href": "https://chat.makerdao.com/channel/community-development"
      }}>{`Community Chat`}</a>{`if you want to know more about submitting a community project idea. `}</p>
    <Aligner center mdxType="Aligner">
      <Button to="/funding/community-projects/community-projects-process/" mdxType="Button">
  Community Projects Process
      </Button>
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      